import React from 'react'
import Layout from 'src/layouts/BaseLayout'

import pageContext from './pageContext.json'

import {
  FaqSection,
  GaranteSuperOfertas,
  HeroInterDayApp,
  MegaPromocoes,
  PraZerarAlista,
  SuasLojasMarcasFavoritas,
} from '../inter-day/sections/_index'

const InterDay = () => {
  return (
    <Layout pageContext={pageContext}>
      <HeroInterDayApp />
      <MegaPromocoes />
      <GaranteSuperOfertas />
      <PraZerarAlista />
      <SuasLojasMarcasFavoritas />
      <FaqSection />
    </Layout>
  )
}

export default InterDay
